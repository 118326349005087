import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";

const PageBackdrop = (props) => {
  return (
    <StyledEngineProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >{props.children}</Backdrop>
    </StyledEngineProvider>
  );
};

export default PageBackdrop;
