import React, { useState, useEffect } from "react";

function DateRangeFilter({ toggleDateFilterModal, onDateChange }) {
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [processing, setProcessing] = useState(false);
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (new Date(newStartDate) > new Date(today)) return;
    setStartDate(newStartDate);
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate("");
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (new Date(newEndDate) > new Date(today)) return;
    setEndDate(newEndDate);
    if (startDate && new Date(startDate) > new Date(newEndDate)) {
      setStartDate("");
    }
  };

  const handleApplyFilter = (e) => {
    e.preventDefault();
    setProcessing(true);
    let finalStartDate = startDate;
    if (endDate && !startDate) {
      const firstDayOfMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      )
        .toISOString()
        .split("T")[0];
      finalStartDate = firstDayOfMonth;
      setStartDate(finalStartDate);
    }

    onDateChange(finalStartDate || "", endDate || "");
    toggleDateFilterModal();
    setProcessing(false);
  };

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{
        top: "0",
        left: "0",
        width: "100vw",
        zIndex: "1050",
        height: "100vh",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className="modal-dialog w-100" role="document">
        <form onSubmit={handleApplyFilter}>
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Date Range
              </h5>
              <button
                type="button"
                aria-label="Close"
                className="header-close-btn"
                onClick={toggleDateFilterModal}
              >
                <span aria-hidden="true" className="close-btn-span">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body mt-0 hide-scrollbar d-space-between">
              <div className="w-100">
                <label htmlFor="startDate">From</label>
                <input
                  type="date"
                  max={today}
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  style={{ backgroundColor: "#8898aa17" }}
                  className="form-control form-control-alternative"
                />
              </div>
              <div className="w-100 ml-3">
                <label htmlFor="endDate">To</label>
                <input
                  id="endDate"
                  type="date"
                  name="endDate"
                  value={endDate}
                  min={startDate || ""}
                  max={today}
                  onChange={handleEndDateChange}
                  style={{ backgroundColor: "#8898aa17" }}
                  className="form-control form-control-alternative"
                />
              </div>
            </div>

            <div
              className="modal-footer d-center py-2"
              style={{ border: "1px solid #e9ecef" }}
            >
              <button
                type="submit"
                className="btn btn-default w-50 text-sm"
                disabled={processing}
              >
                Select
                {processing && <i className="fa fa-spinner fa-spin ml-2"></i>}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DateRangeFilter;
