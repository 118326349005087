import axios from "axios";

const AFFILIATE_EARNING = "affiliate-earning";
const W9_FORM = "w9-form";

export async function fetchAffiliateUsers(filter = {}, url = null) {
  const config = {
    params: {
      ...filter,
    },
  };
  const endpoint = url || `/${AFFILIATE_EARNING}/users`;
  return await axios
    .get(endpoint, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchAffiliateStats(affiliate_id = null) {
  const data = `${affiliate_id ? `?affiliate_id=${affiliate_id}` : ""}`;
  return await axios
    .get(`/${AFFILIATE_EARNING}/stats${data}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchTransactions(filter = {}, url = null) {
  const config = {
    params: {
      ...filter,
    },
  };
  const endpoint = url || `/${AFFILIATE_EARNING}/transactions`;
  return await axios
    .get(endpoint, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchUserPayouts(status) {
  const data = `${status ? `?status=${status}` : ""}`;
  return await axios
    .get(`/${AFFILIATE_EARNING}/get-account-details${data}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function getStripeDashboardLink() {
  return await axios
    .get(`/${AFFILIATE_EARNING}/stripe-dashboard-link`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchW9Status() {
  return await axios
    .get(`/${AFFILIATE_EARNING}/${W9_FORM}/status`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchW9UserRequests(filter = {}, url = null) {
  const config = {
    params: {
      ...filter,
    },
  };
  const endpoint = url || `/admin/${AFFILIATE_EARNING}/all-forms`;
  return await axios
    .get(endpoint, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createStripePayoutAccount() {
  return await axios
    .post(`/${AFFILIATE_EARNING}/create-stripe-account`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
export async function deleteStripePayoutAccount() {
  return await axios
    .post(`/${AFFILIATE_EARNING}/delete-stripe-account`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function withdrawEarnedAmount(data) {
  return await axios
    .post(`/${AFFILIATE_EARNING}/make-payouts`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updatePayoutStatus(data) {
  return await axios
    .post(`/${AFFILIATE_EARNING}/payout-status`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateUserW9Status(uuid, data) {
  return await axios
    .post(`admin/${AFFILIATE_EARNING}/${uuid}/status`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateUserW9Terms(data) {
  return await axios
    .post(`${AFFILIATE_EARNING}/${W9_FORM}/terms-status`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function uploadW9Form(data) {
  return await axios
    .post(`/${AFFILIATE_EARNING}/${W9_FORM}/upload`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
