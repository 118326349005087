import Graphs from "./components/Graphs";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Accounts from "./components/Accounts";
import "../../assets/css/affiliateProgram.css";
import React, { useEffect, useState } from "react";
import MembersCount from "./components/MembersCount";
import MembersStats from "./components/MembersStats";
import { toastrOnTopCenter } from "../../utils/toastr";
import { getCurrentUser } from "../../redux/selectors";
import { LEVEL_ADMIN } from "../../utils/userPermissions";
import DateRangeFilter from "./components/DateRangeFilter";
import { usePermissions } from "../../context/permissionsContext";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import DotPulseBackdrop from "../shared/components/DotPulseBackdrop";
import TermsConditionsModal from "./components/TermsConditionsModal";
import { fetchAffiliateStats, fetchAffiliateUsers } from "../../api/Affiliate";

function AffiliateProgram() {
  const {
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const { hasPermission } = usePermissions();
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [payoutsPaused, setPayoutsPaused] = useState();
  const [paginationData, setPaginationData] = useState({});
  const [affiliateStats, setAffiliateStats] = useState({});
  const [affiliateUsers, setAffiliateUsers] = useState([]);
  const [currentPageUrl, setCurrentPageUrl] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const currentUser = useSelector((state) => getCurrentUser(state));
  const [showDateFilterModal, setShowDateFilterModal] = useState(false);

  useEffect(() => {
    setShowTermsModal(true);
    fetchAffiliateStatsData();
    fetchAffiliateUsersData();
  }, [payoutsPaused]);

  const fetchAffiliateStatsData = async (filter = {}, url = null) => {
    setIsLoading(true);
    setError(null);
    try {
      const statsResponse = await fetchAffiliateUsers(filter, url);
      setAffiliateStats(statsResponse.data);
      setPaginationData(statsResponse.data.affiliated_users);
      setCurrentPageUrl(
        statsResponse.data.affiliated_users.links?.[1]?.url || null
      );
      reset((prevData) => ({ ...prevData, ...statsResponse }));
    } catch (error) {
      console.error("Error fetching stats:", error);
      toastrOnTopCenter(
        "Failed to fetch stats. Please try again later.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAffiliateUsersData = async () => {
    const affiliate_id = null;
    setIsLoading(true);
    setError(null);
    try {
      const usersResponse = await fetchAffiliateStats(affiliate_id);
      setAffiliateUsers(usersResponse.data);
      setPayoutsPaused(usersResponse.data?.is_pause);

      reset((prevData) => ({ ...prevData, users: usersResponse.users }));
    } catch (error) {
      console.error("Error fetching users:", error);
      toastrOnTopCenter(
        "Failed to fetch users. Please try again later.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (url) => {
    if (!url) return;
    setCurrentPageUrl(url);
    fetchAffiliateStatsData(
      { query: searchQuery || null, from_date: startDate, to_date: endDate },
      url
    );
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleQuerySubmit = (e) => {
    e.preventDefault();
    fetchAffiliateStatsData({
      query: searchQuery || null,
      from_date: startDate,
      to_date: endDate,
    });
    fetchAffiliateUsersData({
      query: searchQuery || null,
      from_date: startDate,
      to_date: endDate,
    });
  };

  const clearFilter = () => {
    setEndDate("");
    setStartDate("");
    setSearchQuery("");
    fetchAffiliateStatsData({ query: null });
    fetchAffiliateUsersData({ query: null });
  };

  const handleDateFilterChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
    fetchAffiliateStatsData({
      query: searchQuery || null,
      from_date: start,
      to_date: end,
    });
    fetchAffiliateUsersData({
      query: searchQuery || null,
      from_date: start,
      to_date: end,
    });
  };

  const toggleDateFilterModal = () => {
    setShowDateFilterModal(false);
    document.body.classList.remove("modal-open");
  };

  const toggleTermsModal = () => {
    setShowTermsModal(false);
    document.body.classList.remove("modal-open");
  };

  return (
    <>
      <div className="row justify-content-center">
        <Accounts
          users={affiliateUsers}
          payoutsPaused={payoutsPaused}
          setPayoutsPaused={setPayoutsPaused}
        />
        <MembersCount formData={affiliateStats} />
      </div>
      <div className="row justify-content-center">
        <Graphs graphData={affiliateUsers} />
      </div>
      <div className="d-end mr-4 pr-2 mb-3">
        <button
          className="btn btn-light-outlined border p-2"
          onClick={() => setShowDateFilterModal(true)}
        >
          <span className="date-icon">
            <i className="fa fa-calendar text-dark text-xs"></i>
          </span>
          <span className="text-xs">Date Range</span>
        </button>
      </div>
      <div className="row justify-content-center">
        <MembersStats
          errors={errors}
          formData={affiliateStats}
          searchQuery={searchQuery}
          onClearFilter={clearFilter}
          onPageChange={handlePageChange}
          paginationData={paginationData}
          onInputChange={handleInputChange}
          onQuerySubmit={handleQuerySubmit}
        />
      </div>
      {showTermsModal &&
        !currentUser.w9_checked &&
        !hasPermission(LEVEL_ADMIN) && (
          <TermsConditionsModal toggleTermsModal={toggleTermsModal} />
        )}
      {showDateFilterModal && (
        <DateRangeFilter
          onDateChange={handleDateFilterChange}
          toggleDateFilterModal={toggleDateFilterModal}
        />
      )}
      {isLoading && <DotPulseBackdrop />}
    </>
  );
}

export default AuthenticatedLayout(AffiliateProgram);
