import React from "react";
import { Avatar, AvatarGroup } from "@mui/material";

function MembersCount({ formData }) {
  return (
    <div className="col-lg-3 col-6 px-0 mb-2">
      <div className="card mb-2">
        <div
          className="card-body p-3"
          style={{
            maxHeight: "196px",
            height: "196px",
          }}
        >
          <h3 className="mb-0">Total Members</h3>
          <p
            style={{
              fontSize: 59,
              color: "#000",
              fontWeight: 600,
              marginBottom: 0,
              textAlign: "center",
            }}
          >
            {formData?.members?.count ?? 0}
          </p>
          <AvatarGroup
            max={5}
            spacing={12}
            sx={{
              "& .MuiAvatar-root": {
                width: 30,
                height: 30,
                fontSize: 15,
                position: "relative",
                border: "3px solid #c0e1f5",
                transition: "all 0.15s ease",
                boxShadow: "0px 0px 4.982945919036865px 0px #00000040",
                "&:hover": {
                  transform: "scale(1.5)",
                  boxShadow: "0px 0px 8px 2px #00000060",
                },
              },
            }}
          >
            {formData?.members?.images?.map((avatar, index) => (
              <Avatar key={index} alt={`Member ${index + 1}`} src={avatar} />
            ))}
          </AvatarGroup>
        </div>
      </div>
    </div>
  );
}

export default MembersCount;
