import PageBackdrop from "../../shared/components/PageBackdrop";
import React from "react";
import "../../../assets/css/dot-pulse.css?v1.1";

const DotPulseBackdrop = () => {
  return (
    <PageBackdrop open={true}>
      <div className="dot-pulse"></div>
    </PageBackdrop>
  );
};

export default DotPulseBackdrop;
