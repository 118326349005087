import "../../assets/css/clear-button.css?v1.1";
import "../../assets/css/promote.css?v1.3";

import React, { useState, useEffect } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import { Link, useSearchParams } from "react-router-dom";
import { getExclusiveMemberListing } from "../../api/PromoteApi";
import { toastrOnTopCenter } from "../../utils/toastr";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../redux/selectors";
import { downloadCSV } from "../../utils/csvDownload";
import { FormControlLabel } from "@mui/material";
import PaginationButtons from "../shared/components/PaginationButtons";
import PageLoader from "../shared/components/PageLoader";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import { usePermissions } from "../../context/permissionsContext";
import {
  DOWNLINES,
  MEMBER_INFO_VIEW,
  AFFILIATE_INFO_VIEW,
  DOWNLOAD_CSV,
  ADMIN_DASHBOARD,
} from "../../utils/userPermissions";
import {
  GUEST_BADGE,
  ENAGIC_BADGE,
  TRIFECTA_BADGE,
  ADVISOR_BADGE,
} from "../../utils/globalConstants";

const ExclusiveMembers = () => {
  const { hasPermission } = usePermissions();

  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchQuery = searchParams.get("query") || "";
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);

  const funnelType = "exclusive_member";
  const period = searchParams.get("period");
  const filterableRoleParam = searchParams.get("filterable_role");
  const adminStatsFilter = searchParams.get("admin_stats_filter");

  const currentUser = useSelector((state) => getCurrentUser(state));
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [coachmarkContent, setCoachmarkContent] = useState(
    "Users who were invited by your trainees. You are an advisor to them"
  );

  let filterable_role = null;
  if (filterableRoleParam) {
    filterable_role = atob(filterableRoleParam);
  }

  const [filter, setFilter] = useState({
    user_uuid: currentUser.uuid,
    paginated: true,
    downlines: searchParams.get("downlines") === "true",
    funnel_type: funnelType || "",
    period: period || "all",
    query: null,
    filterable_role: filterable_role,
    admin_stats_filter: adminStatsFilter,
  });

  useEffect(() => {
    getTableValues(filter);
  }, [filter]);

  const getTableValues = async (filter, url) => {
    try {
      setLoading(true);

      const response = await getExclusiveMemberListing(filter, url);

      setTableData(response.data.collection);
      setPaginationData(response.data.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastrOnTopCenter(error.message, "error");
    }
  };

  const handlePageChange = (url) => {
    getTableValues(filter, url);
  };

  const handleDownload = async () => {
    try {
      const newFilter = { ...filter, paginated: false };
      const response = await getExclusiveMemberListing(newFilter);

      if (
        !response.data ||
        !response.data.collection ||
        response.data.collection?.length === 0
      ) {
        throw new Error("No data available to download.");
      }

      const csvData = response.data.collection.map((member) => ({
        ...member,
        zip: member.address.zipcode,
        city: member.address.city,
        state: member.address.state,
      }));

      const customHeaders = [
        "Email",
        "Phone",
        "First Name",
        "Last Name",
        "Zip",
        "City",
        "State",
      ];
      downloadCSV(csvData, customHeaders, "members.csv");
    } catch (error) {
      toastrOnTopCenter(error.message, "error");
    }
  };

  const handleDownlines = () => {
    const newDownlinesValue = !filter.downlines;

    setFilter((prevFilter) => ({
      ...prevFilter,
      downlines: newDownlinesValue,
    }));
    setSearchParams((prevSearchParams) => {
      const newDownlinesParam = new URLSearchParams(prevSearchParams);
      if (newDownlinesValue) {
        newDownlinesParam.set("downlines", "true");
      } else {
        newDownlinesParam.delete("downlines");
      }
      return newDownlinesParam;
    });
    getTableValues({ ...filter, downlines: newDownlinesValue });
  };

  const handleQuerySubmit = (event) => {
    event.preventDefault();
    const newSearchParams = new URLSearchParams(searchParams);

    if (searchQuery !== "") {
      newSearchParams.set("query", searchQuery);
    } else {
      newSearchParams.delete("query");
    }
    setSearchParams(newSearchParams);

    getTableValues({ ...filter, query: searchQuery });
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClearClick = () => {
    setSearchQuery("");
    searchParams.delete("query");
    setSearchParams(searchParams);
    getTableValues({ ...filter, query: null });
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-11 col-12">
          <div className="row">
            <div className="col-md-12">
              {hasPermission(DOWNLOAD_CSV) && (
                <div className="d-flex justify-content-end pb-4">
                  <button className="btn btn-light" onClick={handleDownload}>
                    Download CSV
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <form onSubmit={handleQuerySubmit}>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        value={searchQuery}
                        onChange={handleInputChange}
                      />
                      {searchQuery && (
                        <a
                          className="clear-btn"
                          type="button"
                          onClick={handleClearClick}
                        >
                          <span className="fa fa-times-circle-o"></span>
                        </a>
                      )}
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-primary"
                          type="submit"
                          id="button-addon2"
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {hasPermission(DOWNLINES) && (
                  <div className="col-md-4">
                    <div className="d-flex justify-content-end">
                      <div className="coachmark-outer">
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Switch
                              checked={filter.downlines}
                              onChange={handleDownlines}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Downlines"
                        />
                        <span
                          className={`coachmark-i mr-4 ml-0 ${
                            isHovered ? "hovered" : ""
                          }`}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          data-content={coachmarkContent}
                        >
                          i
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="large-table">#</th>
                      <th className="large-table">Join Date</th>
                      <th>Name</th>
                      <th>Email / Phone</th>
                      {hasPermission(ADMIN_DASHBOARD) && <th>Address</th>}
                      <th className="large-table">Instagram</th>
                      {hasPermission(AFFILIATE_INFO_VIEW) && (
                        <th className="large-table">Affiliates</th>
                      )}
                      <th>Calendar Notifications</th>
                    </tr>
                  </thead>

                  {(() => {
                    if (loading) {
                      return (
                        <tbody>
                          <tr>
                            <td style={{ height: "50vh" }}>
                              <PageLoader />
                            </td>
                          </tr>
                        </tbody>
                      );
                    } else if (tableData?.length === 0) {
                      return (
                        <tbody>
                          <tr>
                            <td colSpan="8" className="text-center">
                              <p style={{ textAlign: "center" }}>
                                No record found.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      );
                    } else {
                      return tableData.map((member, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="large-table">
                              {index +
                                1 +
                                (paginationData.current_page - 1) *
                                  paginationData.per_page}
                            </td>
                            <td className="large-table">
                              {dayjs(member.join_date).format("MMM DD")}
                            </td>
                            <td>
                              {hasPermission(MEMBER_INFO_VIEW) ? (
                                <Link to={`/profile/${member.uuid}`}>
                                  {member.name}{" "}
                                </Link>
                              ) : (
                                <span>{member.name}</span>
                              )}
                              {member.achieved_badges ? (
                                <>
                                  {member.achieved_badges.includes(
                                    GUEST_BADGE
                                  ) ? (
                                    <b> g </b>
                                  ) : null}
                                  {member.achieved_badges.includes(
                                    ENAGIC_BADGE
                                  ) ? (
                                    <b> e </b>
                                  ) : null}
                                  {member.achieved_badges.includes(
                                    TRIFECTA_BADGE
                                  ) ? (
                                    <b> t </b>
                                  ) : null}
                                  {member.achieved_badges.includes(
                                    ADVISOR_BADGE
                                  ) ? (
                                    <b> a </b>
                                  ) : null}
                                </>
                              ) : null}
                            </td>
                            <td>
                              {member.email}
                              {member.phone && (
                                <p
                                  style={{
                                    fontSize: "11px",
                                    margin: "0",
                                  }}
                                >
                                  {member.phone}
                                </p>
                              )}
                            </td>
                            {hasPermission(ADMIN_DASHBOARD) && (
                              <td>
                                {member.address && (
                                  <>
                                    {member.address.address}{" "}
                                    {member.address.city}
                                    <br />
                                    {member.address.state}{" "}
                                    {member.address.zipcode}
                                  </>
                                )}
                              </td>
                            )}
                            <td className="large-table">
                              {member.instagram ? (
                                <a
                                  href={`https://www.instagram.com/${member.instagram}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  @{member.instagram}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                            {hasPermission(AFFILIATE_INFO_VIEW) && (
                              <td className="large-table">
                                <Link to={`/profile/${member.affiliate.uuid}`}>
                                  {member.affiliate.name}
                                </Link>
                              </td>
                            )}
                            <td>
                              <div className="text-center">
                                {member.notifications.event_email && (
                                  <span className="fa fa-envelope pr-2"></span>
                                )}
                                {member.notifications.event_sms && (
                                  <span className="fa fa-phone"></span>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ));
                    }
                  })()}
                </table>
              </div>
              <div className="d-center py-3">
                {paginationData && paginationData.links?.length > 3 && (
                  <PaginationButtons
                    paginationData={paginationData}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthenticatedLayout(ExclusiveMembers);
