import {
  uploadW9Form,
  fetchW9Status,
  fetchUserPayouts,
  withdrawEarnedAmount,
  createStripePayoutAccount,
  deleteStripePayoutAccount,
  getStripeDashboardLink,
} from "../../api/Affiliate";
import Swal from "sweetalert2";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import "../../assets/css/affiliateProgram.css";
import { useSearchParams } from "react-router-dom";
import { getCurrentUser } from "../../redux/selectors";
import { toastrOnTopCenter } from "../../utils/toastr";
import { globalImages } from "../../utils/staticImages";
import React, { useRef, useEffect, useState } from "react";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import DotPulseBackdrop from "../shared/components/DotPulseBackdrop";
import { documentPreview, truncateFileName } from "../../utils/generalHelper";

function MyEarnings() {
  const fileInputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [w9Data, setW9Data] = useState("");
  const [bankData, setBankData] = useState("");
  const status = searchParams.get("status") ?? "";
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const currentUser = useSelector((state) => getCurrentUser(state));

  const w9Empty = w9Data == null;
  const w9Pending = w9Data?.status === "pending";
  const w9Rejected = w9Data?.status === "rejected";
  const w9Approved = w9Data?.status === "approved";
  const HAS_ACTIVE_TRIAL = currentUser.trial_user;
  const IS_PAYOUTS_PAUSE = bankData?.payout_setting?.is_pause;
  const HAS_ACTIVE_SUBSCRIPTION = currentUser.has_active_subscription;

  useEffect(() => {
    fetchData();
    fetchW9Data();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchUserPayouts(status);
      setBankData(response.data);
    } catch (error) {
      console.error("error", error);
      toastrOnTopCenter(
        "Failed to fetch data. Please try again later.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchW9Data = async () => {
    setIsLoading(true);
    try {
      const response = await fetchW9Status();
      setW9Data(response.data);
    } catch (error) {
      console.error("error", error);
      toastrOnTopCenter(
        "Failed to fetch data. Please try again later.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const createPayoutAccount = async () => {
    if (!HAS_ACTIVE_SUBSCRIPTION || HAS_ACTIVE_TRIAL) {
      toastrOnTopCenter("Please purchase a subscription plan first.", "error");
      return;
    }
    if (w9Empty || w9Pending || w9Rejected) {
      toastrOnTopCenter(
        "Accounts can only be linked once your W-9 form is approved.",
        "error"
      );
      return;
    }
    if (w9Rejected) {
      toastrOnTopCenter("Your W-9 form is Rejected. Upload it again!", "error");
      return;
    }
    Swal.fire({
      title: "Confirm Redirect",
      text: "You will be redirected to Stripe to complete the setup.",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await createStripePayoutAccount();
          const url = response?.data?.url;
          if (url) {
            window.location.href = url;
          }
          if (response?.message === "Account is already existed") {
            toastrOnTopCenter(response.message, "error");
          }
        } catch (error) {
          console.error("error", error);
          toastrOnTopCenter(
            "Failed to create a payout account. Please try again.",
            "error"
          );
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const deletePayoutAccount = async () => {
    Swal.fire({
      title: "Remove Account",
      text: "Are you sure to remove this account.",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await deleteStripePayoutAccount();
          fetchData();
          setWithdrawAmount("");
          toastrOnTopCenter(
            response?.message,
            response?.status == false ? "error" : "success"
          );
        } catch (error) {
          console.error("error", error);
          toastrOnTopCenter(
            "Failed to remove your payout account. Please try again later.",
            "error"
          );
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const handleGoToStripeDashboard = async () => {
    setIsLoading(true);
    try {
      const response = await getStripeDashboardLink();
      if (response?.data?.url) {
        window.open(response.data.url, "_blank");
      } else {
        toastrOnTopCenter("Failed to retrieve Stripe Dashboard link.", "error");
      }
    } catch (error) {
      console.error("Error redirecting to Stripe:", error);
      toastrOnTopCenter("Error occurred. Please try again later.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleWithdraw = async () => {
    if (!HAS_ACTIVE_SUBSCRIPTION || HAS_ACTIVE_TRIAL) {
      toastrOnTopCenter("Please purchase a subscription plan first.", "error");
      return;
    }
    if (w9Pending || w9Rejected || w9Empty) {
      toastrOnTopCenter(
        "Withdrawals will be enabled once your W-9 form is approved.",
        "error"
      );
      return;
    }
    if (IS_PAYOUTS_PAUSE) {
      toastrOnTopCenter(
        bankData.payout_setting.message || "Payouts disabled.",
        "error"
      );
      return;
    }
    if (!withdrawAmount || withdrawAmount < 49) {
      toastrOnTopCenter("Please enter a valid amount to withdraw.", "error");
      return;
    }

    Swal.fire({
      title: "Confirm Withdrawals",
      text: `Are you sure you want to withdraw $${withdrawAmount}?`,
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-secondary",
      },
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
          const response = await withdrawEarnedAmount({
            amount: withdrawAmount,
          });
          fetchData();
          toastrOnTopCenter(response?.message, "success");
          setIsLoading(false);
          setWithdrawAmount("");
        } catch (error) {
          console.error("error", error);
          toastrOnTopCenter(
            "Failed to withdraw payment. Please try again later.",
            "error"
          );
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const selectW9Form = async (event) => {
    const file = event.target.files[0];
    if (
      file &&
      (file.type === "application/pdf" || file.type.includes("word"))
    ) {
      setSelectedFile(file);
    } else {
      toastrOnTopCenter("Please select a valid PDF or DOC file.", "error");
    }
  };

  const updateW9Form = async () => {
    if (!selectedFile) {
      toastrOnTopCenter("No file selected.", "error");
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await uploadW9Form(formData);
      toastrOnTopCenter(
        response?.data?.message || "File uploaded successfully!",
        "success"
      );
      setSelectedFile(null);
      fetchW9Data();
    } catch (error) {
      toastrOnTopCenter("Failed to upload file. Please try again.", "error");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const triggerFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="row justify-content-center mt-3">
      <div className="col-11 px-0">
        <div className="row">
          <div className="col-md-6 col-12 pr-2">
            {/* upload w9 form */}
            <div className="card mb-3">
              {!w9Rejected && w9Data && (
                <div className="card-body pt-3">
                  <div className="row mt-2 border-dashed-2px py-2">
                    <div className="col-7 d-center text-primary">
                      <i className="fa fa-file-o text-xl p-2"></i>
                      <span>{truncateFileName(w9Data.original_name, 20)}</span>
                    </div>
                    <div className="col-5 d-center">
                      <button
                        className="btn btn-default px-2 py-1"
                        onClick={() => documentPreview(w9Data.file_path)}
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                  <div className="text-left pt-2">
                    <span style={{ fontSize: 14, color: "#000" }}>
                      {w9Pending && (
                        <>
                          <span className="text-danger">●</span> Your W-9 form
                          is under review and will be approved soon
                        </>
                      )}
                      {w9Rejected && (
                        <>
                          <span
                            className="text-danger border border-danger rounded-pill mr-2"
                            style={{
                              padding: "0px 3px 0px 4px",
                              fontSize: "15px",
                            }}
                          >
                            &#10005;
                          </span>
                          <span style={{ fontSize: 16 }}>Rejected</span>
                        </>
                      )}
                      {w9Approved && (
                        <>
                          <span
                            className="text-success border border-success rounded-pill mr-2"
                            style={{
                              padding: "0px 4px 1px 5px",
                              fontSize: "15px",
                            }}
                          >
                            &#x2713;
                          </span>
                          <span style={{ fontSize: 16 }}>Approved</span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!w9Data || w9Rejected ? (
                <div className="card-body px-4 pb-3">
                  <div className="row border-dashed-2px rounded-sm py-2">
                    <div className="col-2 d-center cursor-pointer">
                      <Tooltip
                        title="Upload File"
                        placement="top"
                        className="pl-4"
                      >
                        <img
                          src={globalImages.cloud_upload}
                          onClick={triggerFileSelection}
                          style={{
                            height: 30,
                            width: 30,
                            aspectRatio: "1",
                            objectFit: "cover",
                            position: "relative",
                          }}
                        />
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={selectW9Form}
                        />
                      </Tooltip>
                    </div>
                    <div className="col-6 text-left lh-100 py-3">
                      {selectedFile ? (
                        truncateFileName(selectedFile.name, 20)
                      ) : (
                        <>
                          <span style={{ fontSize: 14, color: "#000" }}>
                            Select a file or upload
                          </span>{" "}
                          <br />
                          <span style={{ fontSize: 10, color: "#989898" }}>
                            add the feature to upload W9 Form
                          </span>{" "}
                          <br />
                          <span style={{ fontSize: 12, color: "#989898" }}>
                            (Upload Document)
                          </span>
                        </>
                      )}
                    </div>
                    <div className="col-4 d-center">
                      {!selectedFile ? (
                        <label className="btn btn-outline-primary">
                          Select File
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx"
                            style={{ display: "none" }}
                            onChange={selectW9Form}
                          />
                        </label>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={updateW9Form}
                          disabled={isLoading}
                        >
                          {isLoading ? "Uploading..." : "Upload"}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="text-left pt-2">
                    <span style={{ fontSize: 14, color: "#000" }}>
                      {w9Rejected ? (
                        <>
                          <span
                            className="text-danger border border-danger rounded-pill mr-2"
                            style={{
                              padding: "0px 3px 0px 4px",
                              fontSize: "15px",
                            }}
                          >
                            &#10005;
                          </span>
                          <span style={{ fontSize: 16 }}>Rejected</span>
                        </>
                      ) : (
                        <>
                          <span className="text-success">●</span> Upload your
                          W-9 form for admin approval to enable withdrawals
                        </>
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Link Bank account */}
            <div className="card">
              <div className="card-body pt-3">
                <h3>Linked Accounts</h3>
                <span>All transactions are secure and encrypted.</span>
                {bankData?.bank_account?.bank_name && (
                  <div className="row mt-2">
                    <div className="col-3 d-center">
                      <span className="avatar avatar-lg border rounded-pill bg-transparent">
                        <img
                          src={globalImages.bank}
                          style={{
                            height: 30,
                            width: 30,
                            aspectRatio: "1",
                            objectFit: "cover",
                            position: "relative",
                            backgroundColor: "red",
                          }}
                        />
                      </span>
                    </div>
                    <div className="col-5 text-center">
                      <span className="font-weight-bold text-sm">
                        {bankData?.bank_account?.bank_name}
                      </span>{" "}
                      <br />
                      <span className="text-sm">
                        ************{bankData?.bank_account?.last4}
                      </span>{" "}
                    </div>
                    <div className="col-4 d-center">
                      <button
                        className="btn btn-default px-2 py-1"
                        onClick={() => deletePayoutAccount()}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {!bankData?.bank_account?.bank_name && (
                <div
                  className="card-body pt-3 d-center border-top cursor-pointer"
                  style={{
                    minHeight:
                      w9Pending || w9Rejected || w9Approved || selectedFile
                        ? "131px"
                        : "99px",
                  }}
                  onClick={() => createPayoutAccount()}
                >
                  <i className="fa fa-plus-circle mr-2 text-xl text-default"></i>
                  <div className="text-center">
                    <span className="text-underline" style={{ fontSize: 20 }}>
                      Link a new Account
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* withdraw amount */}
          <div className="col-12 col-md-6 pl-2">
            <div className="card">
              <div className="card-body pt-3">
                <div className="w-100 bg-primary rounded-sm">
                  <div
                    className="media align-items-center"
                    style={{ position: "relative", padding: "15px" }}
                  >
                    <span className="avatar avatar-xl rounded-circle">
                      <img
                        src={currentUser.avatar}
                        style={{
                          objectFit: "cover",
                          aspectRatio: "1",
                          position: "relative",
                        }}
                      />
                    </span>
                    <div className="ml-3">
                      <h3 className="mb-0">{currentUser.name}</h3>
                      <p className="mb-0">{currentUser.latest_role}</p>
                    </div>
                  </div>
                </div>
                <div className="w-100 py-4">
                  <h4 className="mb-0 text-gray text-center">Balance</h4>
                  <h1 className="mb-0 text-xxl text-center text-dark">
                    ${bankData.balance ?? 0}
                  </h1>
                </div>
                <div className="w-100 bg-primary rounded-sm py-2 px-3 pt-4">
                  <input
                    type="number"
                    name="withdrawAmount"
                    placeholder="Enter Amount"
                    disabled={bankData?.bank_account?.status !== "enabled"}
                    className="w-100 bg-primary withdrawl-input text-center"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(Number(e.target.value))}
                  />
                  <br />
                  <div className="d-center mt-2">
                    <Tooltip
                      title={
                        !bankData?.bank_account?.bank_name &&
                        "Link your account"
                      }
                      placement="right"
                      className="w-100"
                    >
                      <span>
                        <button
                          className="btn btn-default w-100"
                          disabled={
                            !bankData?.bank_account?.bank_name ||
                            Number(bankData?.bank_account?.balance) < 1 ||
                            Number(withdrawAmount) <= 0 ||
                            Number(withdrawAmount) > Number(bankData.balance)
                          }
                          onClick={handleWithdraw}
                        >
                          Withdraw
                        </button>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <DotPulseBackdrop />}
    </div>
  );
}

export default AuthenticatedLayout(MyEarnings);
