import React from "react";
import PaginationButtons from "../../shared/components/PaginationButtons";
import { Link, useNavigate } from "react-router-dom";
import { usePermissions } from "../../../context/permissionsContext";
import { LEVEL_ADMIN } from "../../../utils/userPermissions";

function MembersStats({
  error,
  formData,
  paginationData,
  searchQuery,
  onInputChange,
  onQuerySubmit,
  onClearFilter,
  onPageChange,
}) {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const userEarning = (id, name) => {
    navigate(`/affiliate-program/income-history?id=${id}&name=${name}`);
  };

  return (
    <>
      <div className="col-lg-11 col-6 px-0">
        <div className="card">
          <div className="card-body pt-3">
            <div className="d-end">
              {searchQuery && (
                <span
                  onClick={onClearFilter}
                  className="mr-2 border border-1 text-sm rounded-pill bg-gradient-gray text-white px-2 py-1 cursor-pointer shortcut-media"
                  role="button"
                  tabIndex={0}
                  aria-label="Clear filter"
                >
                  Clear Filter &times;
                </span>
              )}
              <div
                id="datatable-basic_filter"
                className="dataTables_filter pr-0 pt-3"
              >
                <form
                  className="leaderboard-grid-search"
                  onSubmit={onQuerySubmit}
                >
                  <div className="input-group mb-3">
                    <input
                      required
                      type="text"
                      disabled={formData?.affiliated_users?.data?.length === 0}
                      style={{ height: "31px" }}
                      className="form-control"
                      placeholder="Search..."
                      aria-label="Search users"
                      value={searchQuery}
                      onChange={onInputChange}
                    />
                    <div
                      style={{ height: "31px" }}
                      className="input-group-append"
                    >
                      <button
                        type="submit"
                        id="button-addon2"
                        style={{ padding: "0px 15px" }}
                        className="btn btn-outline-primary"
                        disabled={
                          formData?.affiliated_users?.data?.length === 0 ||
                          searchQuery === ""
                        }
                        aria-label="Search"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="table-responsive table-hover">
              <table className="table table-striped">
                <thead style={{ borderTop: "0" }}>
                  <tr>
                    {hasPermission(LEVEL_ADMIN) && (
                      <th className="border-top-0">Affiliate Name</th>
                    )}
                    <th className="border-top-0">Name</th>
                    <th className="border-top-0">Joining Date</th>
                    <th className="border-top-0">Level</th>
                    <th className="border-top-0">Amount</th>
                    <th className="border-top-0">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.affiliated_users?.data?.length > 0 ? (
                    formData?.affiliated_users?.data?.map((user, index) => (
                      <tr
                        key={index}
                        className="cursor-pointer"
                        onClick={() =>
                          hasPermission(LEVEL_ADMIN) &&
                          userEarning(user.affiliate_id, user.affiliate_name)
                        }
                      >
                        {hasPermission(LEVEL_ADMIN) && (
                          <td>
                            <span
                              style={{
                                backgroundColor: "#15A30933",
                                color: "#15A309",
                                padding: "3px 8px",
                                borderRadius: "15px",
                              }}
                            >
                              {user.affiliate_name}
                            </span>
                          </td>
                        )}
                        <td>
                          <span
                            style={{
                              ...(!hasPermission(LEVEL_ADMIN) && {
                                backgroundColor: "#15A30933",
                                color: "#15A309",
                                padding: "3px 8px",
                                borderRadius: "15px",
                              }),
                            }}
                          >
                            {user.name}
                          </span>
                        </td>
                        <td>{user.joining_date}</td>
                        <td>
                          <span className="badge badge-dot mr-4">
                            <i className="bg-success"></i>
                            {user.level}
                          </span>
                        </td>
                        <td>$ {user.earning}</td>
                        <td>
                          <span
                            style={{
                              backgroundColor:
                                user?.status === "Payout Received"
                                  ? "#15A30933"
                                  : user?.status === "Payout In Review"
                                  ? "#FFD70033"
                                  : user?.status === "Subscription Pending"
                                  ? "#C6070733"
                                  : "inherit",
                              color:
                                user?.status === "Payout Received"
                                  ? "#15A309"
                                  : user?.status === "Payout In Review"
                                  ? "#FFB703"
                                  : user?.status === "Subscription Pending"
                                  ? "#C60707"
                                  : "inherit",
                              padding: "3px 8px",
                              borderRadius: "15px",
                            }}
                          >
                            {user?.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        No data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pagination-container text-center pb-2">
            <span>
              Showing {paginationData.from || 0} to {paginationData.to || 0} of{" "}
              {paginationData.total || 0} entries
            </span>
            <span className="d-center">
              {paginationData.links?.length > 3 && (
                <PaginationButtons
                  paginationData={paginationData}
                  onPageChange={onPageChange}
                />
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(MembersStats);
