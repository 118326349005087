let globalImages = {
  bank: require("../assets/images/bank.png"),
  girl: require("../assets/images/images.jpg"),
  favicon: require("../assets/images/favicon.ico"),
  webclip: require("../assets/images/webclip.png"),
  r2f_logo: require("../assets/images/r2f_icon.png"),
  lines: require("../assets/images/lines.svg").default,
  r2f_logo_1: require("../assets/images/R2F-logo-1.jpg"),
  play_store: require("../assets/images/google-play.png"),
  save_icon: require("../assets/images/save-icon.svg").default,
  app_store: require("../assets/images/app-store.svg").default,
  instagram_icon: require("../assets/images/Group-18.svg").default,
  cloud_upload: require("../assets/images/icons/common/cloud-upload.png"),
};

let registerPageImages = {
  flash_arrow: require("../assets/images/Group-42.svg").default,
  paypal: require("../assets/images/paypal-checkout.png"),
  payment_cards: require("../assets/images/CARDS.png"),
  lock_icon: require("../assets/images/locked-down.png"),
  rtf_bundle: require("../assets/images/rtf-bundle.png"),
  testimonial_1: require("../assets/images/testimonial_9.jpg"),
  testimonial_2: require("../assets/images/testimonial_10.jpg"),
  testimonial_3: require("../assets/images/testimonial_11.jpg"),
  testimonial_4: require("../assets/images/testimonial_12.jpg"),
  testimonial_5: require("../assets/images/testimonial_13.jpg"),
};

let thankyouPageImages = {
  check_green: require("../assets/images/check_green.svg").default,
  testimonial_5_500px: require("../assets/images/20210127-testimonial-500.png"),
  testimonial_5_800px: require("../assets/images/20210127-testimonial-800.png"),
  testimonial_5: require("../assets/images/20210127-testimonial.png"),
};

let welcomePageImages = {
  watch_now_btn: require("../assets/images/watch-now.png"),
  arrow: require("../assets/images/arrow.svg").default,
  testimonial_work_from_home: require("../assets/images/testimonial-work-from-home.png"),
  testimonial_extra_income: require("../assets/images/testimonial-extra-income.png"),
  testimonial_extra_income_500: require("../assets/images/testimonial-extra-income-500.png"),
  testimonial_extra_income_800: require("../assets/images/testimonial-extra-income-800.png"),
  testimonial_pay_off_debt: require("../assets/images/testimonial-pay-off-debt.png"),
  testimonial_quit_high_stress: require("../assets/images/testimonial-quit-high-stress.png"),
  testimonial_quit_high_stress_500: require("../assets/images/testimonial-quit-high-stress-500.png"),
  testimonial_quit_high_stress_800: require("../assets/images/testimonial-quit-high-stress-800.png"),
  testimonial_support_their_families: require("../assets/images/testimonial-support-their-families.png"),
  testimonial_support_their_families_500: require("../assets/images/testimonial-support-their-families-500.png"),
  testimonial_support_their_families_800: require("../assets/images/testimonial-support-their-families-800.png"),
  testimonial_build_sustainable_wealth: require("../assets/images/testimonial-build-sustainable-wealth.png"),
  testimonial_build_sustainable_wealth_500: require("../assets/images/testimonial-build-sustainable-wealth-500.png"),
  testimonial_build_sustainable_wealth_800: require("../assets/images/testimonial-build-sustainable-wealth-800.png"),
};

let leadModalIcons = {
  user_icon: require("../assets/images/Group-16.svg").default,
  envelop_icon: require("../assets/images/Group-17.svg").default,
  instagram_icon: require("../assets/images/Group-18.svg").default,
};

let webinarPageImages = {
  cover: require("../assets/images/bundle-2.png"),
  cover_500: require("../assets/images/bundle-2-p-500.png"),
  cover_800: require("../assets/images/bundle-2-p-800.png"),
  cover_1080: require("../assets/images/bundle-2-p-1080.png"),
  personalize_coaching_icon: require("../assets/images/Group-32.svg").default,
  online_education_icon: require("../assets/images/Group-31.svg").default,
  thriving_community_icon: require("../assets/images/Group-33.svg").default,
  business_toolkit_icon: require("../assets/images/Group-35.svg").default,
  live_call_icon: require("../assets/images/Group-34.svg").default,
  high_paid_health_broker: require("../assets/images/High-Paid-Health-Broker-Masterclass-pc.png"),
  high_paid_health_broker_500: require("../assets/images/High-Paid-Health-Broker-Masterclass-pc-p-500.png"),
  high_paid_health_broker_800: require("../assets/images/High-Paid-Health-Broker-Masterclass-pc-p-800.png"),
  financial_wellness: require("../assets/images/Financial-Wellness-101-Training-macbook.png"),
  financial_wellness_500: require("../assets/images/Financial-Wellness-101-Training-macbook-p-500.png"),
  financial_wellness_800: require("../assets/images/Financial-Wellness-101-Training-macbook-p-800.png"),
  capital_secret: require("../assets/images/Capital-Secrets-Training-ipad.png"),
  capital_secret_500: require("../assets/images/Capital-Secrets-Training-ipad-p-500.png"),
  capital_secret_800: require("../assets/images/Capital-Secrets-Training-ipad-p-800.png"),
  r2f_community: require("../assets/images/Race-To-Freedom-Academy-Membership-box.png"),
  r2f_community_500: require("../assets/images/Race-To-Freedom-Academy-Membership-box-p-500.png"),
  member_event_pricing: require("../assets/images/Member-Event-Pricing-Card.png"),
  member_event_pricing_500: require("../assets/images/Member-Event-Pricing-Card-p-500.png"),
  six_figure_kick_off_call: require("../assets/images/Six-Figure-Kick-Off-Call-iphone.png"),
  six_figure_kick_off_call_500: require("../assets/images/Six-Figure-Kick-Off-Call-iphone-p-500.png"),
  testimonial_existed_community_review: require("../assets/images/IMG_5934.jpg"),
  testimonial_1: require("../assets/images/1a.jpg"),
  testimonial_2: require("../assets/images/1s.jpg"),
  testimonial_3: require("../assets/images/2.jpg"),
  testimonial_4: require("../assets/images/3.jpg"),
  testimonial_5: require("../assets/images/3aa.jpg"),
  testimonial_6: require("../assets/images/3mg.jpg"),
  testimonial_7: require("../assets/images/4.jpg"),
  testimonial_8: require("../assets/images/4e.jpg"),
  testimonial_9: require("../assets/images/5.jpg"),
  testimonial_10: require("../assets/images/5j.jpg"),
  testimonial_11: require("../assets/images/6.jpg"),
  testimonial_12: require("../assets/images/11.jpg"),
  testimonial_13: require("../assets/images/44.jpg"),
  testimonial_14: require("../assets/images/222.jpg"),
  testimonial_15: require("../assets/images/1111.jpg"),
  testimonial_16: require("../assets/images/11111.jpg"),
  testimonial_17: require("../assets/images/22222.jpg"),
  testimonial_18: require("../assets/images/1111111.jpg"),
  testimonial_19: require("../assets/images/IMG_5877.jpg"),
  testimonial_20: require("../assets/images/IMG_5881.jpg"),
  testimonial_21: require("../assets/images/IMG_5893.jpg"),
  testimonial_22: require("../assets/images/IMG_5910.jpg"),
  testimonial_23: require("../assets/images/IMG_5911.jpg"),
  testimonial_24: require("../assets/images/IMG_5921.jpg"),
  testimonial_25: require("../assets/images/IMG_5923.jpg"),
  testimonial_26: require("../assets/images/IMG_5927.jpg"),
  testimonial_27: require("../assets/images/IMG_5929.jpg"),
  testimonial_28: require("../assets/images/IMG_5934.jpg"),
  testimonial_29: require("../assets/images/IMG_5937.jpg"),
  testimonial_30: require("../assets/images/IMG_5939.jpg"),
  testimonial_31: require("../assets/images/IMG_5941.jpg"),
  testimonial_32: require("../assets/images/IMG_5961.jpg"),
  testimonial_33: require("../assets/images/IMG_5962.jpg"),
  testimonial_34: require("../assets/images/IMG_5964.jpg"),
  testimonial_35: require("../assets/images/IMG_5975.jpg"),
  testimonial_36: require("../assets/images/IMG_6335.jpg"),
  testimonial_37: require("../assets/images/IMG_6374.jpg"),
};

let calendarImages = {
  no_subscription: require("../assets/images/blocked_zoom_link.png"),
};

export {
  globalImages,
  registerPageImages,
  thankyouPageImages,
  welcomePageImages,
  leadModalIcons,
  webinarPageImages,
  calendarImages,
};
