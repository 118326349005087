import moment from "moment-timezone";
import "../../assets/css/affiliateProgram.css";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { downloadCSV } from "../../utils/csvDownload";
import { toastrOnTopCenter } from "../../utils/toastr";
import { globalImages } from "../../utils/portalImages";
import { LEVEL_ADMIN } from "../../utils/userPermissions";
import DateRangeFilter from "./components/DateRangeFilter";
import { usePermissions } from "../../context/permissionsContext";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import PaginationButtons from "../shared/components/PaginationButtons";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { fetchAffiliateStats, fetchTransactions } from "../../api/Affiliate";
import DotPulseBackdrop from "../shared/components/DotPulseBackdrop";

function IncomeHistory() {
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  const [endDate, setEndDate] = useState("");
  const { hasPermission } = usePermissions();
  const [orderBy, setOrderBy] = useState("");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const affiliate_id = searchParams.get("id") ?? "";
  const affiliate_name = searchParams.get("name") ?? "";
  const [affiliateUsers, setAffiliateUsers] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [showDateFilterModal, setShowDateFilterModal] = useState(false);

  useEffect(() => {
    fetchTransactionData(currentPage, orderBy);
    fetchAffiliateUsersData();
  }, [currentPage, orderBy]);

  const fetchTransactionData = async (page, orderBy, paginated = false) => {
    setLoading(true);
    try {
      const response = await fetchTransactions({
        paginated,
        affiliate_id,
        per_page: 15,
        page,
        orderBy,
        start_date: startDate,
        end_date: endDate,
      });
      setData(response?.data?.data);
      setPaginationData({
        ...response.data,
        links: response.data.links || [],
      });
    } catch (error) {
      toastrOnTopCenter(error.message, "error");
      console.error("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAffiliateUsersData = async () => {
    setLoading(true);

    try {
      const usersResponse = await fetchAffiliateStats(affiliate_id);
      setAffiliateUsers(usersResponse?.data?.stats);
    } catch (error) {
      toastrOnTopCenter(
        "Failed to fetch users. Please try again later.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadTransactionData = async () => {
    try {
      const response = await fetchTransactions({ paginated: false });
      if (!response?.data.data || response.data.data.length === 0) {
        throw new Error("No data available to download.");
      }

      const csvData = response.data.data.map((transaction) => ({
        ...transaction,
        date: moment
          .utc(transaction.processing_date)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD hh:mm A"),
        transfer_id: transaction.transfer_id,
        transaction_id: transaction.transaction_id,
        payout_id: transaction.payout_id,
        expected_arrival_date: moment
          .utc(transaction.expected_arrival_date)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD"),
      }));

      const customHeaders = [
        "Amount",
        "Status",
        "Date",
        "Transfer ID",
        "Payout ID",
        "Est. Arrival",
      ];
      downloadCSV(csvData, customHeaders, "income_history.csv");
    } catch (error) {
      toastrOnTopCenter(error.message, "error");
      console.error("CSV Download Error:", error.message);
    }
  };

  const handlePageChange = (url) => {
    if (url) {
      const page = new URL(url).searchParams.get("page");
      setCurrentPage(Number(page));
    }
  };

  const toggleDateFilterModal = () => {
    setShowDateFilterModal(false);
    document.body.classList.remove("modal-open");
  };

  const stats = [
    {
      label: "Earnings",
      value: `$${affiliateUsers?.total_earning ?? "0.00"}`,
      color: "#2DCD88",
    },
    {
      label: "Withdrawals",
      value: `$${affiliateUsers?.total_withdrawal ?? "0.00"}`,
      color: "#FA6340",
    },
    {
      label: "Balance",
      value: `$${affiliateUsers?.total_balance ?? "0.00"}`,
      color: "#C60707",
    },
    {
      label: "In Process",
      value: `$${affiliateUsers?.total_in_process ?? "0.00"}`,
      color: "#FFB703",
    },
  ];

  const handleDateFilterChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (startDate || endDate) {
      fetchTransactionData(currentPage, orderBy, true);
    }
  }, [startDate, endDate, currentPage, orderBy]);

  return (
    <>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-11 col-6 px-0">
          <div className="d-end">
            <form className="d-flex" method="get" action="">
              <FormControl
                size="small"
                variant="outlined"
                style={{ minWidth: 130, marginRight: 10 }}
              >
                <InputLabel>Order By</InputLabel>
                <Select
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                  style={{ height: 37 }}
                  className="pt-1"
                  label="Order By"
                >
                  <MenuItem value="date">Date</MenuItem>
                  <MenuItem value="amount">Amount</MenuItem>
                  <MenuItem value="status">Status</MenuItem>
                </Select>
              </FormControl>
            </form>
            <button
              className="btn btn-light-outlined border"
              style={{ padding: "7px 9px 7px 9px" }}
              onClick={() => setShowDateFilterModal(true)}
            >
              <span
                style={{
                  backgroundColor: "#EFEFEF",
                  padding: "2px 7px",
                  marginRight: "5px",
                  borderRadius: "4px",
                }}
              >
                <i className="fa fa-calendar text-dark text-xs"></i>
              </span>
              <span className="text-xs">Date Range</span>
            </button>
            <button
              onClick={() => downloadTransactionData()}
              className="btn btn-outline-light text-dark d-center text-xs py-2"
            >
              <img
                src={globalImages.download}
                className="mr-1"
                alt="download"
                height={16}
                width={16}
              />
              Download CSV
            </button>
          </div>
          {hasPermission(LEVEL_ADMIN) && (
            <div className="card mb-0 mt-2">
              <div className="card-body pt-3 pb-3">
                <h3 className="text-capitalize">{affiliate_name}</h3>
                <div className="row pt-2">
                  {stats.map((stat, index) => (
                    <div
                      key={index}
                      className={`col-md-3 col-6 mt-md-0 mt-5 stats-item pl-4 ${
                        index !== 0 ? "border-left" : ""
                      }`}
                    >
                      <div className="pl-1">
                        <p className="stat-value mb-0">{stat.value}</p>
                        <p className="stat-label" style={{ color: stat.color }}>
                          {stat.label}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-11 col-6 px-0">
          <div className="card mt-3">
            <div className="card-body pt-3">
              <div className="table-responsive">
                {loading ? (
                  <div className="mt-5 text-center">
                    <span>Loading...</span>
                  </div>
                ) : (
                  <table className="table table-flush" id="datatable-basic">
                    <thead style={{ borderTop: "0" }}>
                      <tr>
                        <th className="border-top-0">Payout ID</th>
                        <th className="border-top-0">Transfer ID</th>
                        <th className="border-top-0">Est. Arrival</th>
                        <th className="border-top-0">Withdrawals</th>
                        <th className="border-top-0 d-center">Status</th>
                        <th className="border-top-0">Processed At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, index) => (
                        <tr key={index} style={{ backgroundColor: "#f7f7f7" }}>
                          <td>{item.payout_id}</td>
                          <td>{item.transfer_id}</td>
                          <td>
                            {moment
                              .utc(item.expected_arrival_date)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD")}
                          </td>
                          <td>$ {item.amount}</td>
                          <td className="d-center">
                            {(() => {
                              const statusColors = {
                                paid: { bg: "#15A30933", text: "#15A309" },
                                pending: { bg: "#FFD70033", text: "#FFD700" },
                                in_transit: {
                                  bg: "#007BFF33",
                                  text: "#007BFF",
                                },
                                canceled: { bg: "#C6070733", text: "#C60707" },
                                failed: { bg: "#C6070733", text: "#C60707" },
                              };
                              const { bg, text } = statusColors[
                                item?.status
                              ] || {
                                bg: "#EEE",
                                text: "#000",
                              };

                              return (
                                <span
                                  style={{
                                    color: text,
                                    padding: "3px 8px",
                                    backgroundColor: bg,
                                    borderRadius: "15px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.status}
                                </span>
                              );
                            })()}
                          </td>
                          <td>
                            {moment
                              .utc(item.processing_date)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD hh:mm A")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              {data.length > 0 &&
                paginationData.links &&
                paginationData.links.length > 0 && (
                  <div className="d-center mt-3">
                    <PaginationButtons
                      paginationData={paginationData}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}
              {data.length < 1 && (
                <div className="d-center mt-2">
                  <span>No Data</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {showDateFilterModal && (
          <DateRangeFilter
            onDateChange={handleDateFilterChange}
            toggleDateFilterModal={toggleDateFilterModal}
          />
        )}
        {loading && <DotPulseBackdrop />}
      </div>
    </>
  );
}

export default AuthenticatedLayout(IncomeHistory);
