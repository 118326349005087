import React, { useState, useEffect } from "react";
import { updateUserW9Terms } from "../../../api/Affiliate";
import { useNavigate } from "react-router-dom";
import { toastrOnTopCenter } from "../../../utils/toastr";

function TermsConditionsModal({ toggleTermsModal }) {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const updateTermsStatus = async () => {
    setProcessing(true);
    try {
      const response = await updateUserW9Terms();
      toastrOnTopCenter(response?.data?.message, "success");
      toggleTermsModal(false);
    } catch (error) {
      toastrOnTopCenter("Failed to upload file. Please try again.", "error");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: "1050",
      }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <form>
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="modal-title text-dark" id="exampleModalLabel">
                RaceToFreedom Affiliate Program Terms and Conditions
              </h5>
            </div>
            <div
              className="modal-body mt-0 text-dark px-4"
              style={{ maxHeight: "70vh", overflowY: "auto", padding: "15px" }}
            >
              <p className="font-weight-bolder mb-0">
                Effective Date: 25th March 2025
              </p>
              <p>
                Welcome to the RaceToFreedom Affiliate Program. By participating
                in our affiliate program ("Program"), you acknowledge and agree
                to abide by the following legally binding terms and conditions.
                RaceToFreedom is a platform where users can access courses,
                engage with advisors, and participate in the RTF community and
                its features.
              </p>
              <ol>
                <li>
                  <strong>Enrollment Eligibility</strong>
                  <ul>
                    <li>
                      Only active, paying members are eligible to access and
                      participate in the affiliate program.
                    </li>
                    <li>
                      Free trial users may access the affiliate program for the
                      first 7 days. Post-trial, access will be revoked unless
                      upgraded to a paid membership.
                    </li>
                    <li>
                      Guest users and users with expired subscriptions are not
                      eligible for withdrawals.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Access and Navigation</strong>
                  <ul>
                    <li>
                      Affiliates will find the affiliate program under the
                      "Promote" section in the navigation bar, featuring:
                      Introduction, Settings, Funnel Links, System Stats.
                      Affiliate Program
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Affiliate Dashboard Overview</strong>
                  <ul>
                    <li>
                      Upon accessing the "Affiliate Program," affiliates will
                      see stats, graphs and their member listing.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Income History</strong>
                  <ul>
                    <li>
                      This section displays processed withdrawal details,
                      including transaction ID, date, amount, and status (Paid,
                      Pending, In-Transit, Canceled, or Failed), along with
                      filters and a CSV download option.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Withdrawals</strong>
                  <ul>
                    <li>
                      Affiliates are required to upload a valid W-9 form to
                      process payouts.
                    </li>
                    <li>
                      Affiliates can withdraw funds up to their available
                      balance.
                    </li>
                    <li>
                      Withdrawals cannot exceed the current balance; attempts to
                      do so will prompt an error message.
                    </li>
                    <li>Minimum withdrawal amount is $49.</li>
                    <li>
                      No withdrawals are permitted until total earnings reach
                      $49.
                    </li>
                    <li>
                      Payment will be made to the card details saved by the
                      user, with the ability to edit and manage these details.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Commission Rules</strong>
                  <ul>
                    <li>
                      Commission rate is 30% of each referred user’s payment.
                    </li>
                    <li>
                      Only active, paying members qualify for commissions.
                    </li>
                    <li>
                      If an affiliate’s subscription expires, access to the
                      affiliate program will be suspended with the notice.
                    </li>
                    <li>
                      All earnings accumulated during suspension will become
                      accessible upon re-subscription.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Notifications</strong>
                  <ul>
                    <li>
                      Affiliates will receive notifications regarding
                      withdrawals, successful affiliate payouts, and system
                      updates.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Restrictions</strong>
                  <ul>
                    <li>Self-referrals are strictly prohibited.</li>
                    <li>
                      Misrepresentation or false advertising of RaceToFreedom or
                      its services is forbidden.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Termination and Enforcement</strong>
                  <ul>
                    <li>
                      RaceToFreedom reserves the right to terminate an
                      affiliate’s participation in the program at its sole
                      discretion for any violation of these terms or for any
                      activity deemed unethical or fraudulent. In such cases,
                      RaceToFreedom retains the right to withhold or forfeit any
                      unpaid commissions or payouts.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Limitation of Liability</strong>
                  <ul>
                    <li>
                      RaceToFreedom shall not be liable for any indirect,
                      incidental, or consequential damages arising out of
                      participation in the affiliate program.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Modification of Terms</strong>
                  <ul>
                    <li>
                      RaceToFreedom reserves the right to modify these terms and
                      conditions at any time without prior notice. Continued
                      participation in the affiliate program constitutes
                      acceptance of any modified terms.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Governing Law</strong>
                  <ul>
                    <li>
                      These terms shall be governed and construed in accordance
                      with the laws of United States of America.
                    </li>
                  </ul>
                </li>
              </ol>
              <p>
                <span className="font-weight-bold">Contact Us:</span> For
                inquiries, please contact support at{" "}
                <a href="mailto:support@racetofreedom.com">
                  <u>support@racetofreedom.com</u>
                </a>
              </p>
            </div>
            <div
              className="modal-footer d-space-between py-2"
              style={{ borderTop: "1px solid #e9ecef" }}
            >
              <button
                type="button"
                disabled={processing}
                style={{ width: "48%" }}
                onClick={() => navigate("/dashboard")}
                className="btn btn-light text-sm"
              >
                {processing ? (
                  <i className="fa fa-spinner fa-spin ml-2"></i>
                ) : (
                  "Disagree"
                )}
              </button>
              <button
                type="button"
                disabled={processing}
                style={{ width: "48%" }}
                className="btn btn-default text-sm"
                onClick={() => updateTermsStatus()}
              >
                {processing ? (
                  <i className="fa fa-spinner fa-spin ml-2"></i>
                ) : (
                  "Agree"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TermsConditionsModal;
