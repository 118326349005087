import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  CartesianGrid,
} from "recharts";
import { LEVEL_ADMIN } from "../../../utils/userPermissions";
import { usePermissions } from "../../../context/permissionsContext";

const COLORS = ["#219ebc", "#023047", "#ffb703", "#fb5800"];

function Graphs({ graphData }) {
  const { hasPermission } = usePermissions();

  const pieData = [
    {
      name: "Withdrawals",
      value: Number(graphData?.charts?.pie_chart?.total_withdrawal) || 0,
    },
    {
      name: "Balance",
      value: Number(graphData?.charts?.pie_chart?.total_balance) || 0,
    },
    {
      name: "In Process",
      value: Number(graphData?.charts?.pie_chart?.total_in_process) || 0,
    },
  ];

  const hasNoPieData = pieData.every((item) => item.value === 0);

  const barChartData =
    graphData?.charts?.bar_chart?.map((entry) => ({
      time: entry.date,
      usersJoined: entry.value,
    })) || [];

  const hasNoBarData = barChartData.length === 0;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            background: "#fff",
            padding: "5px",
            border: "1px solid #ccc",
          }}
        >
          <p
            style={{ margin: 0 }}
          >{`${payload[0].name}: $${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="col-lg-6 col-6 px-0 pr-4">
        <div className="card mb-0">
          <div className="card-body px-0" style={{ minHeight: "458px" }}>
            <div className="legend d-space-between">
              <div className="mb-auto">
                <h3 className="pl-4 d-start">
                  {hasPermission(LEVEL_ADMIN) ? "Payments" : "$Earnings"}
                </h3>
              </div>
              <div>
                {pieData.map((entry, index) => (
                  <div key={index} className="d-flex align-items-center mr-3">
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: COLORS[index],
                        marginRight: "8px",
                        borderRadius: "20%",
                      }}
                    ></div>
                    <span>{entry.name}</span>
                  </div>
                ))}
              </div>
            </div>
            {!hasNoPieData ? (
              <PieChart width={620} height={290} className="mt-5">
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ value }) => `$${value}`}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            ) : (
              <div className="d-center mh-100 h-auto">
                <span>No data</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-5 col-6 px-0">
        <div className="card mb-3">
          <div className="card-body px-0">
            <h3 style={{ marginBottom: 13 }} className="pl-4">
              Users
            </h3>
            {!hasNoBarData ? (
              <BarChart width={430} height={371} data={barChartData}>
                <CartesianGrid strokeDasharray="" />
                <XAxis dataKey="time" />
                <YAxis
                  tickFormatter={(value) => Math.round(value)}
                  allowDecimals={false}
                  domain={[0, "dataMax"]}
                  tickCount={5}
                />
                <Tooltip />
                <Bar dataKey="usersJoined" fill="#B3E1F8" />
              </BarChart>
            ) : (
              <div className="d-center mh-100 h-auto">
                <span>No data</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Graphs;
