import moment from "moment-timezone";
import { Tooltip } from "@mui/material";
import "../../assets/css/affiliateProgram.css";
import React, { useEffect, useState } from "react";
import { downloadCSV } from "../../utils/csvDownload";
import { toastrOnTopCenter } from "../../utils/toastr";
import { globalImages } from "../../utils/portalImages";
import DateRangeFilter from "./components/DateRangeFilter";
import { documentPreview, truncateFileName } from "../../utils/generalHelper";
import authenticatedLayout from "../../layouts/AuthenticatedLayout";
import DotPulseBackdrop from "../shared/components/DotPulseBackdrop";
import PaginationButtons from "../shared/components/PaginationButtons";
import { fetchW9UserRequests, updateUserW9Status } from "../../api/Affiliate";

function W9Requests() {
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [w9FormData, setW9FormData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [paginationData, setPaginationData] = useState({});
  const [currentPageUrl, setCurrentPageUrl] = useState(null);
  const [showDateFilterModal, setShowDateFilterModal] = useState(false);

  useEffect(() => {
    fetchW9Requests({ start_date: startDate, end_date: endDate });
  }, [startDate, endDate]);

  const fetchW9Requests = async (filter = {}, url = null) => {
    setLoading(true);
    try {
      const response = await fetchW9UserRequests(filter, url);
      setW9FormData(response.data.data);
      setPaginationData(response.data);
      setCurrentPageUrl(url);
    } catch (error) {
      console.error("error", error);
      toastrOnTopCenter(
        "Failed to fetch data. Please try again later.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const onPageChange = (newUrl) => {
    if (newUrl) {
      fetchW9Requests({ start_date: startDate, end_date: endDate }, newUrl);
    }
  };

  const handleStatusChange = async (uuid, index, newStatus) => {
    setLoading(true);
    try {
      await updateUserW9Status(uuid, { status: newStatus });
      const updatedData = [...w9FormData];
      updatedData[index].status = newStatus;
      setW9FormData(updatedData);
      toastrOnTopCenter("Status updated successfully", "success");
    } catch (error) {
      console.error("Error updating status:", error);
      toastrOnTopCenter(
        error?.message || "Failed to update status. Please try again.",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDateFilterChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const downloadW9RequestsData = () => {
    if (!w9FormData || w9FormData.length === 0) {
      toastrOnTopCenter("No data available to download.", "error");
      return;
    }

    const csvData = w9FormData.map((request) => ({
      "User Name": request.user.name,
      "Affiliate Name": request.user?.affiliate?.name,
      Date: moment(request.Created_At).format("YYYY-MM-DD hh:mm A"),
      Status: request.status,
    }));

    const customHeaders = ["User Name", "Date", "Affiliate Name", "Status"];
    downloadCSV(csvData, customHeaders, "W9_requests.csv");
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchW9Requests({
      start_date: startDate,
      end_date: endDate,
      user_name: searchQuery,
    });
  };

  const clearFilter = () => {
    setSearchQuery("");
    fetchW9Requests();
  };

  return (
    <>
      <div className="row justify-content-center mt-2">
        <div className="col-lg-11 col-6 px-0">
          <div className="card mt-3">
            <div className="card-body pt-3">
              <div className="d-space-between">
                <div className="d-center">
                  <div
                    id="datatable-basic_filter"
                    className="dataTables_filter pr-0 pt-3"
                  >
                    <form
                      className="leaderboard-grid-search"
                      onSubmit={handleSearchSubmit}
                    >
                      <div className="input-group mb-3">
                        <input
                          required
                          type="text"
                          value={searchQuery}
                          placeholder="Search..."
                          className="form-control"
                          aria-label="Search users"
                          style={{ height: "31px" }}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div
                          style={{ height: "31px" }}
                          className="input-group-append"
                        >
                          <button
                            type="submit"
                            id="button-addon2"
                            aria-label="Search"
                            style={{ padding: "0px 15px" }}
                            className="btn btn-outline-primary"
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {searchQuery && (
                    <span
                      role="button"
                      aria-label="Clear filter"
                      onClick={() => clearFilter()}
                      className="ml-2 border border-1 text-sm rounded-pill bg-gradient-gray text-white px-2 py-1 cursor-pointer"
                    >
                      Clear Filter &times;
                    </span>
                  )}
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-light-outlined border p-2"
                    onClick={() => setShowDateFilterModal(true)}
                  >
                    <span className="date-icon">
                      <i className="fa fa-calendar text-dark text-xs"></i>
                    </span>
                    <span className="text-xs">Date Range</span>
                  </button>
                  <button
                    onClick={downloadW9RequestsData}
                    disabled={w9FormData.length < 1}
                    className="btn btn-outline-light text-dark d-center text-xs py-2"
                  >
                    <img
                      src={globalImages.download}
                      className="mr-1"
                      alt="download"
                      height={16}
                      width={16}
                    />
                    Download CSV
                  </button>
                </div>
              </div>
              {loading ? (
                <div className="mt-5 text-center">
                  <span>Loading...</span>
                </div>
              ) : (
                <div className="table-responsive">
                  <table
                    className="table table-striped"
                    style={{
                      overflow: "hidden",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  >
                    <thead className="bg-default text-white">
                      <tr>
                        <th>User Name</th>
                        <th>Date</th>
                        <th>Affiliate Name</th>
                        <th>Status</th>
                        <th>Document</th>
                      </tr>
                    </thead>
                    <tbody>
                      {w9FormData?.map((data, index) => (
                        <tr key={index}>
                          <td>{data.user.name}</td>
                          <td>
                            {moment
                              .utc(data.created_at)
                              .tz(moment.tz.guess())
                              .format("YYYY-MM-DD hh:mm A")}
                          </td>
                          <td>{data?.user?.affiliate?.name}</td>
                          <td>
                            <select
                              className={`form-control-sm status-custom-dropdown text-center ${
                                data.status === "pending"
                                  ? "bg-default"
                                  : data.status === "rejected"
                                  ? "bg-danger"
                                  : data.status === "approved"
                                  ? "bg-success"
                                  : ""
                              } text-white px-2`}
                              value={
                                data.status === "pending" ? "" : data.status
                              }
                              disabled={data.status !== "pending"}
                              onChange={(e) =>
                                handleStatusChange(
                                  data.uuid,
                                  index,
                                  e.target.value
                                )
                              }
                            >
                              {data.status === "pending" && (
                                <option hidden>Pending</option>
                              )}
                              <option value="approved">Approved</option>
                              <option value="rejected">Rejected</option>
                            </select>
                          </td>
                          <td>
                            <Tooltip
                              title={data.original_name}
                              placement="right"
                            >
                              <button
                                className="btn btn-light-outlined border border-default px-2 py-1 d-space-between"
                                style={{ width: 180 }}
                                onClick={() => documentPreview(data.file_path)}
                              >
                                <span>
                                  {truncateFileName(data.original_name, 11)}
                                </span>
                                <i className="fa fa-eye text-dark"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {w9FormData.length > 0 ? (
                    <div className="pagination-container text-center pb-2">
                      <span>
                        Showing {paginationData.from || 0} to{" "}
                        {paginationData.to || 0} of {paginationData.total || 0}{" "}
                        entries
                      </span>
                      <div className="d-center mt-2">
                        {paginationData.links?.length > 3 && (
                          <PaginationButtons
                            paginationData={paginationData}
                            onPageChange={onPageChange}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="d-center mt-2">
                      <span>No Data</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {showDateFilterModal && (
          <DateRangeFilter
            onDateChange={handleDateFilterChange}
            toggleDateFilterModal={() => setShowDateFilterModal(false)}
          />
        )}
        {loading && <DotPulseBackdrop />}
      </div>
    </>
  );
}

export default authenticatedLayout(W9Requests);
