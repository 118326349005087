import Swal from "sweetalert2";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toastrOnTopCenter } from "../../../utils/toastr";
import { updatePayoutStatus } from "../../../api/Affiliate";
import { LEVEL_ADMIN } from "../../../utils/userPermissions";
import { usePermissions } from "../../../context/permissionsContext";

function Accounts({ users, payoutsPaused = false, setPayoutsPaused }) {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const stats = [
    {
      label: "Earnings",
      value: `$${users?.stats?.total_earning ?? "0.00"}`,
      color: "#2DCD88",
    },
    {
      label: "Withdrawals",
      value: `$${users?.stats?.total_withdrawal ?? "0.00"}`,
      color: "#FA6340",
    },
    {
      label: "Balance",
      value: `$${users?.stats?.total_balance ?? "0.00"}`,
      color: "#C60707",
    },
    {
      label: "In Process",
      value: `$${users?.stats?.total_in_process ?? "0.00"}`,
      color: "#FFB703",
    },
  ];

  const adminStats = [
    {
      label: "Total Payouts",
      value: `$${users?.stats?.total_earning ?? "0.00"}`,
      color: "#2DCD88",
    },
    {
      label: "Withdrawals",
      value: `$${users?.stats?.total_withdrawal ?? "0.00"}`,
      color: "#FA6340",
    },
    {
      label: "Balance",
      value: `$${users?.stats?.total_balance ?? "0.00"}`,
      color: "#C60707",
    },
    {
      label: "In Process",
      value: `$${users?.stats?.total_in_process ?? "0.00"}`,
      color: "#FFB703",
    },
  ];

  const selectedStats = hasPermission(LEVEL_ADMIN) ? adminStats : stats;

  const handleTogglePayouts = async () => {
    if (!payoutsPaused) {
      Swal.fire({
        title: "Are you sure you want to pause payouts?",
        text: "Please enter a reason for pausing payouts:",
        input: "text",
        inputValue:
          "Payouts are temporarily paused by the admin. Please try again later.",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-secondary",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await updatePayoutStatus({
              is_paused: true,
              message: result.value || null,
            });
            setPayoutsPaused(true);
            toastrOnTopCenter(response.message, "success");
          } catch (error) {
            console.error("Error updating payout status:", error);
          }
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure you want to resume payouts?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-secondary",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await updatePayoutStatus({
              is_paused: false,
              message: null,
            });
            setPayoutsPaused(false);
            toastrOnTopCenter(response.message, "success");
          } catch (error) {
            console.error("Error updating payout status:", error);
          }
        }
      });
    }
  };

  useEffect(() => {
    setPayoutsPaused(payoutsPaused);
  }, [payoutsPaused]);

  return (
    <div className="col-lg-8 col-6 px-0 pr-4">
      <div className="card mb-0">
        <div className="card-body pt-3 pb-3">
          <div className="row pt-2">
            {selectedStats.map((stat, index) => (
              <div
                key={index}
                className={`col-md-3 col-6 mt-md-0 mt-5 stats-item pl-4 ${
                  index !== 0 ? "border-left" : ""
                }`}
              >
                <div className="pl-1">
                  <p className="stat-value mb-0">{stat.value}</p>
                  <p className="stat-label" style={{ color: stat.color }}>
                    {stat.label}
                  </p>
                </div>
              </div>
            ))}
            <div
              className="col-md-12 col-6 d-flex align-items-center"
              style={{
                marginTop: "32px",
              }}
            >
              {hasPermission(LEVEL_ADMIN) ? (
                <div className="d-space-between w-100">
                  <div>
                    <span className="mr-2">Pause Payouts</span>
                    <label className="switch mb-0">
                      <input
                        type="checkbox"
                        checked={!payoutsPaused}
                        onChange={handleTogglePayouts}
                      />
                      <span className="sliderr round"></span>
                    </label>
                    <span className="ml-2">Resume Payouts</span>
                  </div>
                  <button
                    className="btn btn-default px-5 py-2"
                    onClick={() => navigate("/admin/w9-form-requests")}
                  >
                    User Requests
                  </button>
                </div>
              ) : (
                <>
                  <Link to="/affiliate-program/my-earnings">
                    <button className="btn btn-default py-2 px-4 mr-2">
                      Initiate Withdraw
                    </button>
                  </Link>
                  <Link to="/affiliate-program/income-history">
                    <button className="btn btn-primary py-2 px-4">
                      Income History
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Accounts;
