import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const FIREBASE_VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
const app = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase messaging is not supported in this browser");
    return null;
  } catch (err) {
    console.error("Error initializing Firebase messaging:", err);
    return null;
  }
})();

export const requestForToken = async () => {
  const msg = await messaging;
  if (!msg) {
    return null;
  }

  return getToken(msg, { vapidKey: FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token - " + err);
      return null;
    });
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {})
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

export const requestPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const msg = await messaging;
      if (!msg) return;

      const token = await getToken(msg, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
    } catch (error) {
      console.error("Error retrieving token:", error);
    }
  } else {
    console.error("Permission denied for notifications");
  }
};

export const onMessageListener = () => {
  return new Promise((resolve, reject) => {
    messaging.then((msg) => {
      if (!msg) return;
      onMessage(
        msg,
        (payload) => {
          resolve(payload);
        },
        (error) => {
          reject(error);
        }
      );
    });
  });
};

export default firebaseConfig;
