export const downloadCSV = (csvData, headers, filename) => {
  const csvContent = generateCSVContent(csvData, headers);

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = filename || "data.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const generateCSVContent = (csvData, headers) => {
  let csvContent = headers.join(",") + "\n";
  csvData.forEach((row) => {
    const values = headers.map((header) => {
      if (header === "First Name" || header === "Last Name") {
        const [firstName, lastName] = getFirstNameAndLastName(row.name);
        return wrapCSVValue(header === "First Name" ? firstName : lastName);
      } else {
        const key = Object.keys(row).find(
          (k) =>
            k.toLowerCase().replace(/\s+/g, "_") ===
            header.toLowerCase().replace(/\s+/g, "_")
        );

        return wrapCSVValue(key ? row[key] : "");
      }
    });
    csvContent += values.join(",") + "\n";
  });
  return csvContent;
};

const getFirstNameAndLastName = (name = "") => {
  const nameParts = name.trim().split(/\s+/);
  return [nameParts[0] || "", nameParts.slice(1).join(" ") || ""];
};

const wrapCSVValue = (value) => {
  if (typeof value === "string" && value.includes(",")) {
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
};
